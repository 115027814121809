import { parseData } from '@devhacker/shared/utils/parseData';
import type { MascotMenu } from '~/types/store/mascot';
import { trimWpDomainInPlace } from '~/utils/trimWpDomain';
import { MascotResponseSchema } from '~/schemas/mascot';
import { cacheApiResponseWithRedis } from '@devhacker/shared/utils/cacheApiResponseWithRedis';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

type MascotState = {
  burgerMenu: MascotMenu | null;
  footer: MascotMenu | null;
};

export const useMascotStore = wrapPiniaStore(
  defineStore('mascot', {
    state: (): MascotState => ({
      burgerMenu: null,
      footer: null,
    }),

    getters: {
      mascotBurgerInfo: (state) => state.burgerMenu,
      mascotFooterInfo: (state) => state.footer,
    },

    actions: {
      async fetchMascot() {
        const { $mascotApi, $redisClient } = useNuxtApp();

        const { data: responseData } = await cacheApiResponseWithRedis(
          'FETCH_MASCOT',
          () => $mascotApi.fetchMascotInfo(),
          $redisClient,
          60 * 60, // 1 hour
        );

        const data = parseData(responseData, MascotResponseSchema);

        trimWpDomainInPlace(data, ['items.burger.link', 'items.footer.link']);

        const {
          items: { burger, footer },
        } = data;

        this.setBurgerMenuMascot(burger);
        this.setFooterMascot(footer);
      },

      setBurgerMenuMascot(payload: MascotMenu) {
        this.burgerMenu = payload;
      },

      setFooterMascot(payload: MascotMenu) {
        this.footer = payload;
      },
    },
  }),
);
