<template>
  <Transition name="fade">
    <div
      v-if="isShow"
      class="mascot-speech__container"
    >
      <div class="mascot-speech__content">
        <div class="mascot-speech__text">
          {{ speechText }}
        </div>
        <button
          class="mascot-speech__close-button"
          @click="$emit('close-click')"
        />
      </div>

      <TheLink
        :link="buttonLink"
        class="mascot-speech__action-button"
        :target="linkTarget"
        @click="$emit('link-click')"
      >
        {{ buttonText }}
      </TheLink>
    </div>
  </Transition>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import TheLink from '~/components/TheLink.vue';
  import { HOME_URL } from '~/constants/config';

  export default defineNuxtComponent({
    name: 'MascotSpeech',

    components: {
      TheLink,
    },

    props: {
      isShow: {
        type: Boolean as PropType<boolean>,
        required: true,
      },

      speechText: {
        type: String as PropType<string>,
        required: true,
      },

      buttonText: {
        type: String as PropType<string>,
        required: true,
      },

      buttonLink: {
        type: String as PropType<string>,
        required: true,
      },
    },

    computed: {
      linkTarget(): '_blank' | undefined {
        // Поведение отличается от обычных ссылок, потому не получится использовать isHomeLink
        // Проверяем название сайта без http\https
        return this.buttonLink.includes(HOME_URL?.split('/')?.[2] ?? '') ? undefined : '_blank';
      },
    },
  });
</script>

<style lang="scss" scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  $backgroundColor: #ed5e42;

  .mascot-speech {
    &__container {
      @include fontNormalText(true);

      position: relative;
      width: 208px;
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;
      border-radius: 8px;
      background: $backgroundColor;
      color: white;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);

      &::after {
        content: '';
        position: absolute;
        border: 16px solid transparent;
        border-top-color: $backgroundColor;
        border-bottom: 0;
        bottom: -8px;
        left: 80%;
      }
    }

    &__text {
      font-weight: 400;
    }

    &__content {
      display: flex;
      gap: 12px;
    }

    &__close-button {
      @include closeMask;
      width: 16px;
      height: 16px;
      background: white;
      flex-shrink: 0;
      cursor: pointer;
      opacity: 0.8;

      @include hover {
        opacity: 1;
      }
    }

    &__action-button {
      @include fontNormalText(true);
      font-weight: 700;

      width: 100%;
      background: white;
      color: black;
      border-radius: 2px;
      border: none;
      padding: 8px 24px;
      cursor: pointer;
      text-align: center;
      text-decoration: none;

      @include hover {
        color: $backgroundColor;
      }
    }
  }
</style>
