<template>
  <div class="categories">
    <div
      v-for="(category, index) in burgerMenuItems"
      :key="index"
      class="categories__item"
    >
      <span
        v-if="category.children.length"
        class="categories__title"
      >
        {{ category.title }}
      </span>
      <div class="links">
        <NavMenuCategoriesItem
          v-for="item of category.children"
          :key="item.id"
          :item="item"
          @click.native="onClickMenuItem(category.title, item.link)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import NavMenuCategoriesItem from './NavMenuCategoriesItem.vue';
  import { getFullUrl } from '~/utils';

  export default defineNuxtComponent({
    name: 'NavMenuCategories',
    components: {
      NavMenuCategoriesItem,
    },
    props: {
      burgerMenuItems: {
        required: true,
        type: Array as PropType<Array<MenuItem>>,
      },
    },
    methods: {
      onClickMenuItem(categoryTitle: string, linkMenuItem: string): void {
        this.$sendYandexMetrika({
          level1: 'Клик_Хедер',
          level4: getFullUrl(linkMenuItem),
          level5: categoryTitle,
          level6: 'Бургер-меню',
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  .categories {
    &__item {
      margin-top: 28px;
      border-bottom: 1px solid #e7e7e7;
      @include hasMouse {
        margin-top: 20px;
      }
    }

    &__title {
      margin-left: 44px;
      font-family: $secondFont;
      line-height: 24px;
      font-weight: 600;
      font-size: 18px;
      @include hasTouch {
        margin-left: 64px;
      }
      @include hasMouse {
        margin-left: 24px;
        font-size: 16px;
      }
    }
  }
</style>
