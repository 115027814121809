<template lang="pug">
  button.push-button(
    type="button"
    v-if="isPushControlVisible",
    @click.prevent="handlePushControlClick",
  ) {{ pushControlTitle }}
</template>

<script lang="ts">
  import { getPushNotificationsManager } from '~/plugins/push-notifications.client';
  import { useIndexStore } from '~/store';
  import isNotificationsSupported from '~/utils/isNotificationsSupported';

  export default defineNuxtComponent({
    name: 'PushControlButton',

    computed: {
      isSubscribedToWebPushNotifications(): boolean {
        return useIndexStore().isSubscribedToWebPushNotifications;
      },

      pushControlTitle(): string {
        return this.isSubscribedToWebPushNotifications
          ? 'Вы подписаны на push'
          : 'Вы не подписаны на push';
      },

      isPushControlVisible(): boolean {
        return isNotificationsSupported();
      },
    },

    methods: {
      handlePushControlClick(): void {
        const pushNotificationsManager = getPushNotificationsManager(this.$pushApi);

        if (this.isSubscribedToWebPushNotifications) {
          pushNotificationsManager.unsubscribe();
        } else {
          pushNotificationsManager.subscribe();
        }
      },
    },
  });
</script>

<style lang="scss" scoped>
  .push-button {
    border: 0;
    background-color: transparent;
    cursor: pointer;
  }
</style>
