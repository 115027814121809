<template>
  <div
    class="category-item"
    :class="classes"
    :data-vars-header-item-title="$isAMP ? item.title : undefined"
    :data-vars-link-href="$isAMP ? getAnalyticsLink(item.link) : undefined"
  >
    <TheLink
      :link="item.link"
      :title="item.title"
      :class="getNavMenuItemClasses"
      :is-active="isActive"
    >
      <template
        v-if="checkCustom"
        #default
      >
        <span
          class="link-text"
          :style="cssVariablesLinkText"
        >
          {{ item.title }}
        </span>
      </template>
    </TheLink>

    <TestPixel
      v-if="item.testPixel && item.testPixel.length && !$isAMP"
      :src="item.testPixel"
    />
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  import TheLink from '~/components/TheLink.vue';
  import TestPixel from '~/components/TestPixel.vue';
  import { getFullUrl, getRelativeLink } from '~/utils';

  export default defineNuxtComponent({
    name: 'NavMenuCategoriesItem',

    components: {
      TheLink,
      TestPixel,
    },

    props: {
      item: {
        type: Object as PropType<MenuItem>,
        required: true,
      },
    },

    computed: {
      classes(): { [propName: string]: boolean } {
        return {
          'category-item__customized': Boolean(this.item.backgroundColor),
          'category-item__customized-text': Boolean(
            this.item.textColor && !this.item.backgroundColor,
          ),
          'category-item__active': this.isActive,
        };
      },

      cssVariablesLinkText(): Record<string, string> {
        const textColor = this.item.textColor;
        const backgroundColor = this.item.backgroundColor;
        if (textColor && backgroundColor) {
          return {
            '--text-color': textColor,
            '--bg-color': backgroundColor,
          };
        } else if (textColor) {
          return {
            '--text-color': textColor,
          };
        } else {
          return {
            '--bg-color': backgroundColor,
          };
        }
      },

      checkCustom(): boolean {
        return Boolean(this.item.backgroundColor || this.item.textColor);
      },

      getNavMenuItemClasses(): Record<string, boolean> {
        // /topics/android/
        return {
          'category-item__active-item': this.isActive,
        };
      },

      isActive(): boolean {
        const relativeLink = getRelativeLink(this.item.link);
        return this.$route.path === relativeLink;
      },
    },

    methods: {
      getAnalyticsLink(link: string): string {
        return getFullUrl(link);
      },
    },
  });
</script>

<style lang="scss">
  .category-item {
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &:first-child {
      margin-top: 8px;
    }
    &:last-child {
      margin-bottom: 24px;
    }

    a {
      width: 100%;
      line-height: 24px;
      padding: 16px 8px 16px 44px;
      display: block;
      font-weight: 400;
      color: #000;

      text-decoration: none;
      font-size: 18px;

      @include hasTouch {
        padding: 16px 8px 16px 64px;
      }

      @include hasMouse {
        padding: 8px 24px;
        font-size: 16px;
      }
    }

    a:not(.category-item__active-item) {
      @include hover {
        background: #e1eafd;
      }
    }

    .category-item__active-item {
      color: #000;
    }
  }

  .category-item__customized a {
    padding: 16px 36px;

    @include hasTouch {
      padding: 16px 8px 16px 56px;
    }
    @include hasMouse {
      padding: 8px 16px;
    }
  }

  .category-item__customized-text {
    span {
      color: var(--text-color);
    }

    a:not(.category-item__active-item) {
      padding: 16px;
      @include hasMouse {
        padding: 12px 16px;
      }
    }
  }

  .category-item__customized span {
    background-color: var(--bg-color);

    color: var(--text-color);
  }

  .category-item__active-item {
    background: var(--bg-color-substrate-3);
    color: white;
    cursor: default;
  }

  .link-text {
    word-break: break-word;
    padding: 2px 8px;

    border-radius: 2px;
  }
</style>
