<template>
  <TheFooter :menu="menu" />
</template>

<script lang="ts">
  import { useMenusStore } from '~/store/menus';
  import TheFooter from '~/components/TheFooter.amp/TheFooter.amp.vue';

  export default defineNuxtComponent({
    name: 'TheFooterContainer',

    components: { TheFooter },

    computed: {
      menu() {
        const menus = useMenusStore().menus;
        return [menus.info, menus.subscribe, menus.rules];
      },
    },
  });
</script>
