<template lang="pug">
  IntersectionObserver(
    @appear="isAppeared = true"
  )
    img.test-pixel(
      v-if="isAppeared"
      :src="isAppeared ? src : ''"
      alt="тестовый пиксель"
    )
</template>

<script lang="ts">
  import type { PropOptions } from 'vue';
  // @ts-ignore
  import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';

  export default defineNuxtComponent({
    name: 'TestPixel',

    components: {
      IntersectionObserver,
    },

    props: {
      src: {
        type: String,
        required: true,
      } as PropOptions<string>,
    },

    data: (): {
      isAppeared: boolean;
    } => ({
      isAppeared: false,
    }),
  });
</script>
