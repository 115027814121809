<template lang="pug">
  .footer-menu
    .footer-menu__list
      .footer-menu__item.footer-menu__item--title {{ title }}
      a.footer-menu__item(
        v-for="item of list"
        :key="item.id"
        :href="item.link"
        :data-vars-link-href="$isAMP ? getAnalyticsLink(item.link) : undefined"
        :data-vars-footer-item-title="$isAMP ? item.title : undefined"
        target="_blank"
        :rel="isAnotherOrigin(item.link) ? 'noopener' : ''"
        @click="onClickMenuItem(item)"
        :class="footerMenuItemClass(item)"
      ) {{ item.title }}
      slot
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { getFullUrl, isAnotherOrigin } from '~/utils';

  export default defineNuxtComponent({
    name: 'TheFooterMenu',

    props: {
      title: {
        type: String as PropType<string>,
        required: true,
      },

      list: {
        type: Array as PropType<Array<FooterMenuItem>>,
        required: true,
      },
    },

    methods: {
      isAnotherOrigin,

      getAnalyticsLink(link: string): string {
        return getFullUrl(link);
      },

      onClickMenuItem(item: FooterMenuItem): void {
        this.$sendYandexMetrika({
          level1: 'Клик_футер',
          level4: getFullUrl(item.link),
          level5: item.title,
        });
      },

      footerMenuItemClass(menuItem: FooterMenu): any {
        const title = menuItem.title.trim().toLowerCase();
        const regex = /^\S[0-9A-Z_a-z]*$/g;

        return {
          [`footer-menu__item--${title}`]: regex.test(title),
        };
      },
    },
  });
</script>

<style lang="scss">
  $titleColor: #a0a2a7;

  .footer-menu {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    @include tablet {
      flex-direction: row;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    @include tablet {
      margin-bottom: 0;
    }

    @include desktop {
      &:last-child {
        .footer-menu__item {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer-menu__title {
    @include fontTextBtn;
    margin-bottom: 16px;
    color: $titleColor;

    @include tablet {
      margin-right: 24px;
    }

    @include desktop {
      margin-bottom: 0;
    }
  }

  .footer-menu__item {
    @include fontTextBtn;

    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    margin-right: 24px;
    white-space: normal;

    @include tablet {
      white-space: nowrap;
    }

    @include hover {
      color: rgba(255, 255, 255, 1);
    }

    &--title {
      width: 100%;
      @include fontTextBtn;
      color: $titleColor;

      @include tablet {
        margin-right: 24px;
        width: auto;
      }

      @include desktop {
        margin-bottom: 0;
      }

      @include hover {
        color: $titleColor;
      }
    }
  }

  // .footer-menu__item[href*=".apple"],
  // .footer-menu__item[href*="play.google"],
  .footer-menu__item--ios,
  .footer-menu__item--android {
    position: relative;
    margin-left: 23px;

    &:before {
      position: absolute;
      content: '';
      width: 18px;
      height: 18px;
      top: 3px;
      left: -23px;
    }
  }

  // .footer-menu__item[href*=".apple"],
  .footer-menu__item--ios {
    &:before {
      background-image: url(images/app-store.png);
    }
  }

  // .footer-menu__item[href*="play.google"],
  .footer-menu__item--android {
    &:before {
      background-image: url(images/google-play.png);
    }
  }

  .footer-menu__list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    row-gap: 16px;

    @include tablet {
      row-gap: 8px;
    }
  }
</style>
