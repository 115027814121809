<template lang="pug">
ErrorBoundary(moduleName="TheFooter.amp")
    template(#error)
        p Неизвестная ошибка
    footer.footer__container(:class="{ isHealth }" :next-page-hide="$isAMP ? true : undefined")
      .footer__menu-list
        TheFooterMenu(
          v-for="(item, index) of menu"
          :key="index"
          :title="item && item.title ? item.title : ''"
          :list="item && item.items ? item.items : []"
        )
          ClientOnly(v-if="item && item.type === 'twenty-subscribe-footer'")
            PushControlButton.footer-menu__item
      .footer__text
        span.footer__text-warning(v-if="isHealth")
          span.footer__text-warning-title Если вас что-то беспокоит, обратитесь к специалисту
          span.footer__text-warning-description Мы создаем этот раздел, потому что нам важно, чтобы вам было на что опереться в вопросах здоровья. Но наши тексты не заменят консультацию врача

        span.footer__text-rating
          span.footer__text-rating-age 18+
          span.footer__text-copyright Копирование материалов запрещено. <br>Издание может получать комиссию от покупки товаров, представленных в публикациях

      MascotFooter(v-if="!$isAMP && !isHealth")
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  import MascotFooter from '../Mascot/MascotFooter.vue';
  import TheFooterMenu from '~/components/TheFooterMenu.amp/TheFooterMenu.amp.vue';
  import PushControlButton from '~/components/PushControlButton.vue';
  import { isHealthPath } from '~/utils/router';
  import ErrorBoundary from '@devhacker/shared/components/ErrorBoundary.vue';

  export default defineNuxtComponent({
    name: 'TheFooter',

    components: {
      TheFooterMenu,
      PushControlButton,
      MascotFooter,
      ErrorBoundary,
    },

    props: {
      menu: {
        type: Array as PropType<Array<FooterMenu>>,
        required: true,
      },
    },

    computed: {
      isHealth(): boolean {
        return isHealthPath(useRoute().path);
      },
    },
  });
</script>

<style lang="scss">
  .footer__container {
    // отрицательный margin для баннера adfox
    // https://youtrack.lifehacker.ru/issue/LH-1123
    margin-bottom: -40px;
  }
</style>

<style lang="scss" scoped>
  $copyrightColor: #a0a2a7;
  $healthCopyrightColor: #777474;
  $ratingColor: #969698;
  $backgroundColor: #222222;

  .footer__container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
    align-items: flex-start;
    background-color: $backgroundColor;

    @include tablet {
      padding: 32px 24px;
    }

    @include big-tablet {
      padding: 32px 96px;
    }

    @include desktop {
      flex-direction: row;
      padding: 56px calc((100vw - 1160px) / 2);
      justify-content: space-between;
    }
  }

  .footer__text-rating-age {
    @include fontH4AccentNormalText;
    color: $ratingColor;
    padding: 5px 4px;
    border: 1px solid $ratingColor;
    border-radius: 4px;
    width: 36px;
    height: 36px;
  }

  .footer__text-warning-description,
  .footer__text-copyright {
    display: inline-block;
    font-family: $mainFont;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    color: $healthCopyrightColor;
  }

  .footer__text-copyright {
    @include desktop {
      max-width: 244px;
    }
  }

  .footer__text-warning {
    display: flex;
    flex-direction: column;
    flex: 1;

    @include tablet {
      min-width: 378px;
    }

    @include desktop {
      min-width: 300px;
      max-width: 300px;
    }
  }

  .footer__text-warning-title {
    display: inline-block;

    color: $ratingColor;
    font-size: 12px;
    line-height: 1.25;
    font-family: $mainFont;
    margin-bottom: 4px;
  }

  .footer__text-rating {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    align-items: center;
    flex-direction: row;

    @include desktop {
      align-items: flex-start;
      flex-direction: column;

      .isHealth & {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .footer__menu-list {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    margin-bottom: 24px;
  }

  .footer__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    &:not(:last-child) {
      margin-bottom: 45px;
    }

    @include mobile {
      &:not(:last-child) {
        margin-bottom: unset;
      }
    }

    @include tablet {
      flex-direction: row;
      align-items: center;
    }

    @include desktop {
      flex-direction: column;
      align-items: flex-start;
    }
  }
</style>
