import { cacheApiResponseWithRedis } from '@devhacker/shared/utils/cacheApiResponseWithRedis';
import { isHealthPath } from '~/utils/router';
import { trimWpDomainInPlace } from '~/utils/trimWpDomain';
import { defineStore } from 'pinia';
import { wrapPiniaStore } from '@devhacker/shared/utils/wrapPiniaStore';

export type Menu = {
  type: string;
  title: string;
  items: MenuItem[];
};

export interface Menus {
  main: Menu | null;
  second: Menu | null;
  info: Menu | null;
  projects: Menu | null;
  subscribe: Menu | null;
  apps: Menu | null;
  rules: Menu | null;
  burgerInfo: Menu | null;
  burgerCategories: Menu | null;
  burgerSubs: Menu | null;
}

type ServerMenu = [keyof Menus, string[]];

const MENUS_NAMES_IN_SERVER: Record<keyof Menus, string[]> = {
  main: ['twenty-main'],
  second: ['twenty-second', 'health-menu-header-2'],
  projects: ['twenty-main-projects', 'health-menu-header-1'],
  info: ['twenty-info-footer', 'health-menu-footer-info'],
  subscribe: ['twenty-subscribe-footer', 'health-menu-footer-subscription'],
  apps: ['twenty-apps-footer'],
  rules: ['twenty-rules-footer', 'health-menu-footer-rules'],
  burgerInfo: ['twenty-burger-info', 'health-menu-burger-info'],
  burgerCategories: ['twenty-burger-categories', 'health-menu-burger-category'],
  burgerSubs: ['twenty-burger-subscriptions', 'health-menu-burger-socials'],
};

export const useMenusStore = wrapPiniaStore(
  defineStore('menus', {
    state: () => ({
      regular: {
        loaded: false,
        main: null as Menu | null,
        second: null as Menu | null,
        info: null as Menu | null,
        rubric: null as Menu | null,
        projects: null as Menu | null,
        subscribe: null as Menu | null,
        apps: null as Menu | null,
        rules: null as Menu | null,
        burgerInfo: null as Menu | null,
        burgerCategories: null as Menu | null,
        burgerSubs: null as Menu | null,
      },
      health: {
        loaded: false,
        main: null as Menu | null,
        second: null as Menu | null,
        info: null as Menu | null,
        rubric: null as Menu | null,
        projects: null as Menu | null,
        subscribe: null as Menu | null,
        apps: null as Menu | null,
        rules: null as Menu | null,
        burgerInfo: null as Menu | null,
        burgerCategories: null as Menu | null,
        burgerSubs: null as Menu | null,
      },
    }),

    actions: {
      async fetchMenusIfNeeded() {
        const route = useRoute();
        const isHealth = isHealthPath(route.path);
        if (isHealth && !this.health.loaded) {
          return this.fetchHealthMenus();
        }
        if (!isHealth && !this.regular.loaded) {
          return this.fetchRegularMenus();
        }
      },

      async fetchRegularMenus() {
        const { $wordpressApi, $redisClient } = useNuxtApp();

        const data = await cacheApiResponseWithRedis(
          'FETCH_REGULAR_MENUS',
          () => $wordpressApi.fetchMenus(),
          $redisClient,
          60 * 60, // 1 hour
        );
        this.updateAllMenus({ type: 'regular', data });
        this.updateRegularMenuLoaded();
      },

      async fetchHealthMenus() {
        const { $healthApi, $redisClient } = useNuxtApp();

        const data = await cacheApiResponseWithRedis(
          'FETCH_HEALTH_MENUS',
          () => $healthApi.fetchMenus(),
          $redisClient,
          60 * 60, // 1 hour
        );
        this.updateAllMenus({ type: 'health', data: data });
        this.updateHealthMenuLoaded();
      },

      updateAllMenus({ type, data }: { type: 'regular' | 'health'; data: any }) {
        trimWpDomainInPlace(data, ['items.link']);
        const entries = Object.entries(MENUS_NAMES_IN_SERVER) as ServerMenu[];
        entries.forEach(([stateName, serverType]) => {
          const item: Menu = data.find((item: Menu) => serverType.includes(item.type));

          if (item) {
            this[type][stateName] = item;
          }
        });
      },

      updateRegularMenuLoaded() {
        this.regular.loaded = true;
      },

      updateHealthMenuLoaded() {
        this.health.loaded = true;
      },
    },

    getters: {
      menus: (state) => {
        const route = useRoute();
        const isHealth = isHealthPath(route.path);
        return isHealth ? state.health : state.regular;
      },

      burgerMenuItems: (state) => {
        const type = isHealthPath(useRoute().path) ? 'health' : 'regular';
        const info = state[type].burgerInfo;
        const categories = state[type].burgerCategories;
        const result = [];
        if (info?.items.length) {
          result.push({
            title: info?.title || 'Информация',
            children: info.items,
          });
        }
        if (categories?.items.length) {
          result.push({
            title: categories?.title || 'Рубрики',
            children: categories.items,
          });
        }
        return result;
      },
    },
  }),
);
