<template>
  <div class="social">
    <a
      :href="social.link"
      :data-vars-link-href="$isAMP ? social.link : undefined"
      target="_blank"
      class="social__link"
    >
      <img
        :src="social.icon"
        :alt="social.title"
        class="social__icon"
      />
    </a>
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { getFullUrl } from '~/utils';

  export default defineNuxtComponent({
    name: 'NavMenuSocialsItem',
    props: {
      social: {
        required: true,
        type: Object as PropType<MenuItemWithIcon>,
      },
    },
    methods: {
      getAnalyticsLink(): string {
        return getFullUrl(this.social.link);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .social {
    height: 52px;
    width: 48px;
    &__icon {
      width: 24px;
      height: 24px;

      @include hover {
        cursor: pointer;
        filter: brightness(80%);
      }
    }
  }
</style>
