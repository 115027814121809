<template>
  <div
    v-if="socialItems && socialItems.length"
    class="socials"
  >
    <div class="socials__title">
      {{ socialTitle }}
    </div>
    <div class="socials__links-wrapp">
      <NavMenuSocialsItem
        v-for="social in socialItems"
        :key="social.id"
        :social="social"
        @click="onClickSocials(social.title, social.link)"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import NavMenuSocialsItem from './NavMenuSocialsItem.vue';
  import { getFullUrl } from '~/utils';

  export default defineNuxtComponent({
    name: 'NavMenuSocials',
    components: {
      NavMenuSocialsItem,
    },
    props: {
      socialTitle: {
        required: true,
        type: String as PropType<string>,
      },
      socialItems: {
        required: true,
        type: Array as PropType<Array<MenuItemWithIcon>>,
      },
    },
    methods: {
      onClickSocials(socialTitle: string, socialLink: string) {
        this.$sendYandexMetrika({
          level1: 'Клик_Хедер',
          level4: getFullUrl(socialLink),
          level5: socialTitle,
          level6: 'Бургер-меню',
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  .socials {
    margin-top: 28px;
    padding: 0 44px 0;
    @include hasTouch {
      padding: 0 64px 0;
    }
    @include hasMouse {
      padding: 0 24px 0;
      margin-top: 20px;
    }
    &__links-wrapp {
      max-width: 150px;
      margin-top: 24px;
      display: flex;
      flex-wrap: wrap;
    }
    &__title {
      font-family: $secondFont;
      line-height: 24px;
      font-weight: 600;
      font-size: 18px;
      @include hasMouse {
        font-size: 16px;
      }
    }
  }
</style>
