<template>
  <Transition name="container-fade">
    <!-- Компонент не восстанавливается после перехода с v-if потому используется v-show для анимации -->
    <!-- Так как mascot может быть null при ошибке запроса делаем на него проверку чтобы не было падения -->
    <div
      v-if="mascot"
      v-show="!containerHidden"
      class="mascot-footer"
    >
      <IntersectionObserver @appear="playAnimation" />
      <MascotSpeech
        class="mascot-footer__speech"
        :is-show="showMenu"
        :button-text="mascot.buttonText"
        :speech-text="mascot.messageText"
        :button-link="mascot.link"
        @close-click="handleClose"
      />
      <div
        class="mascot-footer__wrapper-animation"
        @mouseover="handleMouseover"
        @mouseleave="handleMouseleave"
      >
        <div
          ref="animationContainer"
          class="mascot-footer__animation"
          :class="classHoveredAnimation"
          @click="handleClick"
        />
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
  import MascotSpeech from './MascotSpeech.vue';
  import IntersectionObserver from '@/components/lh-ui/IntersectionObserver/IntersectionObserver.vue';
  import { useMascotStore } from '~/store/mascot';
  import { createScriptIfNeeded } from '~/utils/embeds';

  export default defineNuxtComponent({
    name: 'MascotFooter',

    components: {
      IntersectionObserver,
      MascotSpeech,
    },

    data() {
      return {
        animation: null as null | any,
        showMenu: false as boolean,
        hovered: false as boolean,
        containerHidden: false as boolean,
      };
    },

    computed: {
      mascot() {
        return useMascotStore().mascotFooterInfo;
      },

      containerClass(): Record<string, boolean> {
        return {
          'container--hovered': this.hovered || this.showMenu,
        };
      },

      classHoveredAnimation(): Record<string, boolean> {
        return {
          'mascot-footer__animation--hovered': this.hovered || this.showMenu,
        };
      },
    },

    watch: {
      $route() {
        this.showMenu = false;
        this.containerHidden = false;
        this.hovered = false;
      },
    },

    async mounted() {
      await createScriptIfNeeded('/js/lottie.min.js');

      const animationData = await import('~/assets/lottie/lazeyka-top-crop.json');

      this.$nextTick(() => {
        this.animation = window?.lottie?.loadAnimation({
          container: this.$refs.animationContainer,
          renderer: 'svg',
          loop: true,
          autoplay: false,
          animationData: animationData.default,
          initialSegment: [0, 450],
        });
      });
    },

    methods: {
      playAnimation() {
        if (this.animation) {
          this.animation.play();
          this.animation.loop = true;
        }
      },

      handleClose() {
        this.containerHidden = true;
      },

      handleMouseover() {
        if (this.showMenu) {
          return;
        }

        if (this.animation.currentFrame < 350) {
          this.animation.pause();
        } else {
          this.animation.loop = false;
        }

        this.hovered = true;
      },

      handleMouseleave() {
        this.playAnimation();
        this.hovered = this.showMenu ?? false;
      },

      handleClick() {
        this.playAnimation();
        this.showMenu = true;
      },
    },
  });
</script>

<style lang="scss" scoped>
  .container-fade-enter,
  .container-fade-leave-to {
    opacity: 0;
  }

  .mascot-footer {
    position: absolute;
    bottom: 0;
    right: 4px;

    transition: opacity ease-in 0.3s;

    @include tablet {
      right: 44px;
    }

    @include big-tablet {
      right: 84px;
    }
    @include desktop {
      right: 252px;
    }

    &--hovered {
      bottom: 8px;
    }

    &__wrapper-animation {
      max-height: 70px;
      overflow: hidden;
    }

    &__animation {
      width: 128px;
      height: 112px;
      float: right;
      cursor: pointer;
      position: relative;
      transform: translateY(8px);
      transition: transform ease-out 0.3s;

      &--hovered {
        transform: translateY(0);
      }
    }

    &__speech {
      margin-right: 12px;
      margin-bottom: 14px;
      z-index: 5;
    }
  }
</style>
